import React, { FC } from "react";
import { Button, H2, H4, P, TIcon } from "components";
import Main from "layouts/Main";

import "styles/globals.css";
import ErrorImage from "assets/svg/404.svg";

// styles
const mainStyles = {
  padding: "4rem",
  justifyContent: "center",
};

const imgStyles = {
  maxWidth: "80%",
};

// constans
const ICON: TIcon = {
  position: "right",
  type: "icon",
  component: { name: "BsArrowUpRight" },
};

// markup
const Error: FC = () => (
  <Main>
    <main style={mainStyles}>
      <H2 title="404" />
      <H4 title="Woops..." style={{ color: "#ffffff" }} />
      <img src={ErrorImage} alt="error page illustration" style={imgStyles} />
      <P
        text="We’re sorry, we couldn’t found the page you requested."
        style={{ textAlign: "center" }}
      />
      <Button title="Go to Home" href="/" type="link" icon={ICON} />
    </main>
  </Main>
);

export default Error;
